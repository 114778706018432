import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby";

import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";
import sanityImageUrlBuilder from "web-common/src/utils/imageUrlBuilder";

import './style.scss';

export interface ArticleListingInterface {
    name: string;
    title?: string;
    ctaTitle?: string;
    initialArticleCount?: number;
    articlesPerRow: number;
    sectionPage: {
        slug: {
            current: string;
        }
    }
  }

const ArticleListing: FunctionComponent<ArticleListingInterface> = ({ title, ctaTitle, initialArticleCount, articlesPerRow, sectionPage }) => {
    const { language, market } = useContext(LocalizedContext);
    const { sanityId, sanityDataset } = useSiteMetadata(language);
    const urlBuilder = sanityImageUrlBuilder({
        projectId: sanityId,
        dataset: sanityDataset
    });

    const [filteredArticles, setFilteredArticle] = useState([]);

    const getAllArticles = useStaticQuery(graphql`
        query GetArticles {
            allSanityArticle {
                nodes {
                    market
                    id
                    i18n_lang
                    parentSection {
                        ... on SanitySectionPage {
                            slug {
                                current
                            }
                            name
                        }
                    }
                    heroImage {
                        _rawAsset
                        alt
                    }
                    slug {
                        _key
                        _type
                        current
                    }
                    _rawParentSection
                    headline
                }
            }
        }
    `);

    const filterArticles = () => {
        const filteredList = getAllArticles.allSanityArticle.nodes.filter((article) => {
            return article.parentSection.slug?.current === sectionPage.slug.current && article.market === market;
        });

        setFilteredArticle(filteredList);
        return filteredList;
    }

    useEffect(() => {
        filterArticles();
    }, [getAllArticles]);

    const [nextRow, setNextRow] = useState<number>(4);

    useEffect(() => {
        if(!!initialArticleCount && filteredArticles.length >= initialArticleCount) {
            setNextRow(initialArticleCount);
        }
    }, [initialArticleCount, filteredArticles]);

    const handleMoreImage = () => {
        setNextRow(nextRow + articlesPerRow);
    };

    return (
        <section className="articleListing">
            <div className="container-fluid">
                <h2>{ title }</h2>

                <ul className="articleListing__list">
                    {filteredArticles &&
                        filteredArticles.slice(0, nextRow).map((article: any, index: number) => {
                            let {
                            // eslint-disable-next-line prefer-const
                            heroImage, slug, headline, parentSection
                            }: {
                            slug: { current: string };
                            title: string;
                            image: { alt: string; asset: { _ref: string } };
                            parentSection: {
                                parentSection: any;
                                name: string;
                                slug: { current: string }
                            };
                            } = article;
                            return (
                              <li key={index} className="articleListing__item">
                                <a href={`../${parentSection?.slug.current}/${slug?.current}`}>
                                  <picture>
                                    <source
                                      media={"(min-width: 1200px)"}
                                      srcSet={
                                        urlBuilder
                                          .image(heroImage?._rawAsset?._ref)
                                          .auto("format")
                                          .quality(80)
                                          .width(286)
                                          .height(286)
                                          .format("webp")
                                          .url() as string
                                      }
                                    />
                                    <img
                                      src={
                                        urlBuilder
                                          .image(heroImage?._rawAsset?._ref)
                                          .auto("format")
                                          .quality(80)
                                          .width(265)
                                          .height(265)
                                          .format("webp")
                                          .url() as string
                                      }
                                      alt={heroImage?.alt}
                                    />
                                  </picture>
                                  <h2>{headline}</h2>
                                </a>
                              </li>
                            );
                    })}
                </ul>

                <div className="button-container">
                    {nextRow < filteredArticles?.length && (
                        <button
                            className="btn-primary"
                            onClick={handleMoreImage}
                        >
                            { ctaTitle }
                        </button>
                    )}
                </div>

            </div>
        </section>
    );
};

export default ArticleListing;
